import {
  Text,
  Box,
  Lockup,
  IconQuality,
  IconGift,
  IconShipping,
  IconFavorite,
  CardGrid,
} from '@butcherbox/freezer'
import React from 'react'
import ChooseYourPlanButton from '~/routes/LoggedOut/HowItWorks/modules/ChooseYourPlanButton'
import * as Styles from './Example.css'
export default function HowItWorksExamples() {
  return (
    <Box className={Styles.howItWorksExamples}>
      <CardGrid columns={{ mobile: 1, tablet: 2, desktop: 4 }} gap={16}>
        <Example
          description="We source from partners who share our high standards for quality."
          Icon={IconQuality}
          title="Trusted Sourcing"
        />

        <Example
          description="You choose your box and delivery frequency."
          Icon={IconGift}
          title="Complete Flexibility"
        />

        <Example
          description="We ship your order, frozen for freshness and packed in an eco-friendly box."
          Icon={IconShipping}
          title="Free Shipping"
        />

        <Example
          description="You enjoy high-quality meat delivered to your door."
          Icon={IconFavorite}
          title="Cook With Confidence"
        />
      </CardGrid>
      <Box
        display={{ mobile: 'none', desktop: 'flex' }}
        justifyContent="center"
        marginBottom={32}
      >
        <ChooseYourPlanButton />
      </Box>
    </Box>
  )
}

function Example({
  description,
  Icon,
  title,
  ...props
}: {
  Icon: typeof IconQuality
  title: string
  description: string
}) {
  return (
    <Box className={Styles.example} {...props}>
      <Lockup textAlign="center">
        <Icon display="inlineBlock" variant="duo" />
        <Text variant="Subhead1">{title}</Text>
        <Text color="stone" variant="Body1Regular">
          {description}
        </Text>
      </Lockup>
    </Box>
  )
}
