import BackgroundImage from 'gatsby-background-image'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { device } from '~/styles/device'
import { Box, Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './YouTube.css'

export default function HowItWorksYouTubeUi({ data }) {
  return (
    <BackgroundImage fluid={data.bottomBg.childImageSharp.fluid}>
      <Box className={Styles.YouTube}>
        <Lockup className={Styles.Lockup}>
          <Text color="white" component="h2" variant="Subhead1">
            {data.headline}
          </Text>
          <Text color="white" component="div" variant="H3Bold">
            {data.subhead}
          </Text>
        </Lockup>

        <Box
          className={Styles.ImageLink}
          component="a"
          href={data.youTubeLink.url}
          rel="noopener noreferrer"
          target="_blank"
          title={data.youTubeLink.title}
        >
          <GatsbyImage
            alt={data.altText}
            fluid={[
              data.bottomYouTubeCTAMobile.childImageSharp.fluid,
              {
                ...data.bottomYouTubeCTA.childImageSharp.fluid,
                media: device.tablet,
              },
            ]}
            style={{ margin: 'auto' }}
          />
        </Box>
      </Box>
    </BackgroundImage>
  )
}
