import { LayoutContext } from 'design/contexts/layout'
import React from 'react'
import { Box, BoxProps, Text } from '@butcherbox/freezer'
import * as Styles from './TabbedView.css'

export type Tab = {
  // this ID should be highly unique since it will be persisted to the URL when clicking between tabs
  id: string
  tabContent: React.ReactChild
  triggerContent: React.ReactChild
}

type ITabbedLayout = {
  mobileLayout?: 'stacked' | 'scrollable' | 'default'
  // A descriptive label of the tab layout for screen-readers
  label: string
  selectedTabId?: string
  tabs: Tab[]
  triggerContainerProps?: BoxProps
  tabClick?: (filter: string) => void
} & BoxProps

export default function TabbedView({
  label,
  mobileLayout = 'default',
  selectedTabId,
  tabs = [],
  triggerContainerProps,
  tabClick,
  ...props
}: ITabbedLayout) {
  const tabIndex = tabs.findIndex((tab) => tab.id === selectedTabId)
  const startIndexOfSelectedTab =
    tabIndex !== -1 ? tabs.findIndex((tab) => tab.id === selectedTabId) : 0
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(
    startIndexOfSelectedTab
  )
  const tabRefs = React.useMemo(() => [], [])
  const previousSelectedTabIndex = React.useRef(0)

  const getOrCreateRef = (index: number) => {
    if (!tabRefs[index]) {
      tabRefs[index] = React.createRef()
    }
    return tabRefs[index]
  }

  const handleKeyEvents = (event: React.KeyboardEvent) => {
    if (
      (event.key === 'ArrowLeft' ||
        event.keyCode === 37 ||
        event.key === 'ArrowUp' ||
        event.keyCode === 38) &&
      selectedTabIndex > 0
    ) {
      setSelectedTabIndex(selectedTabIndex - 1)
      document
        .querySelector(`#${tabs[selectedTabIndex - 1].id}-trigger`)
        .scrollIntoView()
    } else if (
      (event.keyCode === 39 ||
        event.key === 'RightArrow' ||
        event.keyCode === 40 ||
        event.key === 'DownArrow') &&
      selectedTabIndex < tabs.length - 1
    ) {
      setSelectedTabIndex(selectedTabIndex + 1)
      document
        .querySelector(`#${tabs[selectedTabIndex + 1].id}-trigger`)
        .scrollIntoView()
    }
  }
  React.useEffect(() => {
    if (selectedTabIndex !== previousSelectedTabIndex.current) {
      tabRefs[selectedTabIndex].current.focus()
      tabRefs[selectedTabIndex].current.click()
      previousSelectedTabIndex.current = selectedTabIndex
    }
  }, [selectedTabIndex, tabRefs])

  function getMobileLayoutStyles(view: ITabbedLayout['mobileLayout']) {
    switch (view) {
      case 'stacked':
        return Styles.TabGrid.stackedTabsOnMobile
      case 'scrollable':
        return Styles.TabGrid.scrollableTabsOnMobile
      default:
        return Styles.TabGrid.default
    }
  }

  return (
    <Box {...props}>
      <Box
        aria-label={label}
        className={getMobileLayoutStyles(mobileLayout)}
        onKeyUp={(event) => handleKeyEvents(event)}
        role="tablist"
        {...triggerContainerProps}
      >
        {tabs.map((tab, index) => (
          <Trigger
            active={selectedTabIndex === index}
            aria-controls={`${tab.id}-panel`}
            data-attr-tab={`${tab.id}-trigger`}
            id={`${tab.id}-trigger`}
            key={tab.id}
            onClick={() => {
              tabClick && tabClick(tab.id)
              setSelectedTabIndex(index)
            }}
            ref={getOrCreateRef(index)}
            showSeparator={index > 0 ? index - 1 !== selectedTabIndex : false}
            showSeparatorMobile={mobileLayout !== 'stacked'}
          >
            {tab.triggerContent}
          </Trigger>
        ))}
      </Box>

      {tabs.map((tab, index) => (
        <Box
          aria-labelledby={`${tab.id}-trigger`}
          hidden={index !== selectedTabIndex}
          id={`${tab.id}-panel`}
          key={tab.id}
          role="tabpanel"
        >
          {tab.tabContent}
        </Box>
      ))}
    </Box>
  )
}

type ITabbedLayoutTrigger = {
  active: boolean
  showSeparator: boolean
  showSeparatorMobile: boolean
} & BoxProps

const Trigger = React.forwardRef(
  (
    {
      active,
      children,
      showSeparator,
      showSeparatorMobile,
      ...props
    }: ITabbedLayoutTrigger,
    ref: React.MutableRefObject<HTMLButtonElement>
  ) => {
    const { isMobile } = React.useContext(LayoutContext)

    const TabClass =
      isMobile && !showSeparatorMobile
        ? Styles.TabVariants.noDecorator
        : active || !showSeparator
        ? Styles.TabVariants.noDecorator
        : Styles.TabVariants.default

    return (
      <Box
        aria-selected={active}
        background={active ? 'white' : 'transparent'}
        boxShadow={active ? 'default' : undefined}
        className={TabClass}
        component="button"
        ref={ref}
        role="tab"
        tabIndex={active ? 0 : -1}
        {...props}
      >
        <Text color="spicedCrimson" component="span" variant="CTA">
          {children}
        </Text>
      </Box>
    )
  }
)
