import { Box, Text } from '@butcherbox/freezer'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { HeroContent } from './Hero.css'

export default function HowItWorksHeroUI({ data }) {
  return (
    <BackgroundImage
      critical
      fadeIn={false}
      fluid={data.topBg.childImageSharp.fluid}
    >
      <Box className={HeroContent}>
        <Text
          color="white"
          component="h1"
          textAlign="center"
          variant="DisplayOne"
        >
          {data.headline}
        </Text>
      </Box>
    </BackgroundImage>
  )
}
