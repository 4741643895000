import { Box, Text, List, ListItem, Lockup } from '@butcherbox/freezer'
import React from 'react'
import { formatPriceWithoutTrailingZeroes } from '~/utils'
import type { BoxTypeExplanationProps } from './OurBoxes.types'
import * as Styles from './BoxTypeExplanation.css'

function BoxTypeExplanation(props: BoxTypeExplanationProps) {
  return (
    <Box className={Styles.BoxTypeExplanation}>
      <Box className={Styles.BoxDetailsLeft}>
        <Box className={Styles.IconWrap}>{props.renderIcon()}</Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <Lockup>
            <Text color="spicedCrimson" component="h3" variant="H1Bold">
              {props.title}
            </Text>
            <Text variant="Body1Regular">{props.description}</Text>
            <List>
              <ListItem>{props.poundage} of high-quality meat</ListItem>
              <ListItem>
                {formatPriceWithoutTrailingZeroes(props.startingPrice * 100)}{' '}
                per month
              </ListItem>
              <ListItem>Free shipping, cancel anytime</ListItem>
            </List>
          </Lockup>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="center">
        <Lockup>
          <Text variant="Subhead1">{props.detailSubheading}</Text>
          <List>
            {props.detailBullets.map((text, index) => (
              <ListItem key={index}>{text}</ListItem>
            ))}
          </List>
          {props.disclaimer && <Text variant="Micro">{props.disclaimer}</Text>}
        </Lockup>
      </Box>
    </Box>
  )
}

export default BoxTypeExplanation
