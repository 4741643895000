import { Box, BoxProps } from '@chakra-ui/core'
import { rem, useTheme } from 'design'
import React, { SVGProps } from 'react'

export default function CardBadge({
  children,
  textProps,
  svgProps,
  ...props
}: BoxProps &
  Omit<SVGProps<any>, 'height' | 'width'> & {
    textProps?: BoxProps
    svgProps?: any
  }) {
  const theme = useTheme()

  return (
    <Box
      color="white"
      height={rem(80)}
      pos="relative"
      width={rem(80)}
      {...props}
    >
      <svg
        data-what="card-badge"
        height="100%"
        style={{ ...svgProps }}
        viewBox="0 0 80 80"
        width="100%"
      >
        <path
          d="M78.733 41.49c-1.143-2.018-1.277-4.438-.269-6.522 1.412-2.958.538-6.52-2.084-8.537-1.815-1.412-2.89-3.63-2.756-5.916.202-3.295-2.017-6.253-5.176-7.06-2.219-.604-4.034-2.218-4.774-4.37-1.075-3.092-4.168-4.974-7.462-4.57-2.286.336-4.571-.538-6.118-2.219-2.218-2.42-5.848-3.025-8.672-1.345-2.017 1.143-4.437 1.278-6.52.27C31.942-.19 28.38.683 26.362 3.304c-1.411 1.816-3.63 2.891-5.915 2.757-3.295-.202-6.253 2.017-7.06 5.176-.604 2.219-2.218 4.034-4.37 4.774-3.024 1.075-4.907 4.167-4.503 7.462.336 2.286-.538 4.57-2.219 6.117-2.42 2.22-3.025 5.849-1.344 8.672 1.143 2.017 1.277 4.438.269 6.521-1.412 2.96-.538 6.522 2.084 8.539 1.815 1.411 2.89 3.63 2.756 5.915-.202 3.295 2.017 6.252 5.176 7.059 2.219.606 4.034 2.218 4.774 4.37 1.075 3.092 4.235 5.042 7.462 4.571 2.285-.336 4.571.538 6.117 2.219 2.219 2.42 5.85 3.025 8.673 1.344 2.016-1.142 4.437-1.276 6.52-.268 2.959 1.411 6.522.537 8.538-2.085 1.412-1.814 3.63-2.89 5.916-2.756 3.294.202 6.253-2.017 7.06-5.177.604-2.218 2.218-4.033 4.369-4.772 3.092-1.076 4.975-4.235 4.571-7.463-.336-2.285.538-4.571 2.219-6.117a6.956 6.956 0 001.277-8.673"
          fill={theme.colors.bb.slate}
        />
        <path
          d="M39.876 7.07c-18.084 0-32.807 14.722-32.807 32.806 0 18.084 14.723 32.807 32.807 32.807 18.083 0 32.807-14.723 32.807-32.807-.068-18.084-14.79-32.807-32.807-32.807zm0 66.42c-18.555 0-33.68-15.127-33.68-33.681 0-18.555 15.125-33.681 33.68-33.681s33.68 15.126 33.68 33.68C73.49 58.432 58.432 73.49 39.877 73.49z"
          fill="currentcolor"
        />
      </svg>

      <Box
        fontFamily="Lato"
        fontSize={rem(13)}
        left="50%"
        lineHeight="1"
        maxW="100%"
        pos="absolute"
        textAlign="center"
        top="50%"
        transform="translate(-50%, -50%) rotate(-30deg)"
        {...textProps}
      >
        {children}
      </Box>
    </Box>
  )
}
