import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  rem,
  Text,
} from '@butcherbox/freezer'
import CardBadge from 'design/components/CardBadge/CardBadge'
import React from 'react'
import { formatPriceFromCents, formatPriceWithoutTrailingZeroes } from '~/utils'
import type { BoxTypeExplanationProps } from './OurBoxes.types'
import * as Styles from './BoxTypeExplanationMobile.css'
function BoxTypeExplanationMobile(props: BoxTypeExplanationProps) {
  return (
    <Box boxShadow="default" marginBottom={24} position="relative">
      {props.isTopChoice && (
        <CardBadge
          pos="absolute"
          right={rem(-14)}
          textProps={{
            fontWeight: 400,
            textTransform: 'none',
          }}
          top={rem(-14)}
        >
          Top Choice
        </CardBadge>
      )}

      <Box background="spicedCrimson" px={16} py={12}>
        <Text color="white" variant="H2Bold">
          {props.title}
        </Text>
      </Box>

      <Box paddingBottom={16} px={16}>
        <Box
          alignItems="center"
          borderBottom="silt"
          display="flex"
          marginBottom={16}
          paddingBottom={16}
          paddingTop={24}
        >
          <Box paddingRight={16} w="100%">
            <Text variant="Body1Regular">{props.description}</Text>
          </Box>
          <Box className={Styles.IconWrap}>{props.renderIcon()}</Box>
        </Box>

        <List>
          <ListItem>{props.poundage} of high-quality meat</ListItem>
          <ListItem>
            {formatPriceFromCents(
              (props.startingPrice * 100) / props.numberOfMeals
            )}{' '}
            per meal
          </ListItem>
          <ListItem>
            {formatPriceWithoutTrailingZeroes(props.startingPrice * 100)} per
            month
          </ListItem>
          <ListItem>Free shipping, cancel anytime</ListItem>
        </List>

        <Accordion id={props.title} marginTop={16} variant="compact">
          <AccordionSummary>View a sample box</AccordionSummary>
          <AccordionDetails>
            <List>
              {props.detailBullets.map((text, index) => (
                <ListItem key={index}>{text}</ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default BoxTypeExplanationMobile
