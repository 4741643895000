import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import HowItWorksExamples from '~/routes/LoggedOut/HowItWorks/modules/Examples/Examples'
import HowItWorksHero from '~/routes/LoggedOut/HowItWorks/modules/Hero/Hero'
import HowItWorksShipping from '~/routes/LoggedOut/HowItWorks/modules/Shipping/Shipping'
import HowItWorksYouTube from '~/routes/LoggedOut/HowItWorks/modules/YouTube/YouTube'
import { cleanJsonStrings } from '~/utils/unicode'
import { CuratedOrCustomAndHowItWorksContainer } from './modules/CuratedOrCustomAndHowItWorksContainer'
import { BOX_TYPES_RAW } from './modules/OurBoxes/OurBoxes.constants'

export default function HowItWorks() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query HowItWorksStaticQuery {
        pageData: contentfulProtectedPage(pageId: { eq: "how-it-works" }) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { header } = useProtectedPage(data?.pageData)

  return (
    <UnauthenticatedLayout cta={header?.cta?.content} title="How It Works">
      <Helmet>
        <script type="application/ld+json">
          {`
            ${JSON.stringify(
              BOX_TYPES_RAW.map((b) => {
                return {
                  '@content': 'https://schema.org',
                  '@type': 'Product',
                  name: b.title,
                  description: b.description,
                  offers: {
                    '@type': 'Offer',
                    price: `${b.startingPrice}.00`,
                    priceCurrency: 'USD',
                  },
                }
              })
            )}
          `}
        </script>
      </Helmet>
      <HowItWorksHero />
      <HowItWorksExamples />
      <CuratedOrCustomAndHowItWorksContainer />
      <HowItWorksShipping />
      <HowItWorksYouTube />
    </UnauthenticatedLayout>
  )
}
