const size = {
  mobile: 320,
  tablet: 768,
  desktop: 1280,
}

export const device = {
  mobileS: `(min-width: ${size.mobile}px)`,
  belowTablet: `(max-width: ${size.tablet - 1}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  belowDesktop: `(max-width: ${size.desktop - 1}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
} as const
