import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { cleanJsonStrings } from '~/utils/unicode'
import HowItWorksHeroUI from './Hero.ui'

export default function HowItWorksHero() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query HowItWorksHeroStaticQuery {
        topBg: file(relativePath: { eq: "how-it-works-top.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 375) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )
  data.headline = 'How It Works'

  return <HowItWorksHeroUI data={data} />
}
