import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { cleanJsonStrings } from '~/utils/unicode'
import HowItWorksYouTubeUi from './YouTube.ui'

export default function HowItWorksYouTube() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query HowItWorksYouTubeStaticQuery {
        bottomBg: file(relativePath: { eq: "how-it-works-bottom.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 585) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        bottomYouTubeCTA: file(
          relativePath: { eq: "how-it-works-youtube-cta.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 848) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }

        bottomYouTubeCTAMobile: file(
          relativePath: { eq: "how-it-works-youtube-cta-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxHeight: 150) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `)
  )
  data.headline = 'Raise the Bar'
  data.subhead = `We believe in better. For us, better means caring about animals and
  our planet. It means improving the livelihoods of farmers. Ultimately,
  it means better meals, enjoyed together. It's why we deliver 100%
  grass-fed, grass-finished beef; free-range organic chicken; pork
  raised crate-free, wild-caught seafood, and more directly to members'
  doors.`
  data.youTubeLink = {
    url: 'https://www.youtube.com/c/ButcherBox/featured',
    title: 'Visit us on YouTube',
  }
  data.altText =
    'Get inspired with recipes, tips, and stories about the people behind your plate.'

  return <HowItWorksYouTubeUi data={data} />
}
