import GatsbyImage from 'gatsby-image'
import React from 'react'
import ChooseYourPlanButton from '~/routes/LoggedOut/HowItWorks/modules/ChooseYourPlanButton'
import { Box, Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './Shipping.css'

export default function HowItWorksShippingUi({ data }) {
  return (
    <Box className={Styles.Shipping}>
      <Box className={Styles.Content}>
        <Lockup paddingTop={{ mobile: 0, tablet: 32 }} px={16}>
          <Text variant="Eyebrow">{data.headline}</Text>
          <Text component="h2" variant="H1Bold">
            {data.subhead}
          </Text>
          <Text variant="Body1Regular">{data.text}</Text>
          <ChooseYourPlanButton />
        </Lockup>
        <GatsbyImage
          alt={data.altText}
          fluid={{
            ...data.grid.childImageSharp.fluid,
            aspectRatio: 1,
          }}
          style={{ gridRow: 1 }}
        />
      </Box>
    </Box>
  )
}
