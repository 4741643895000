import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { cleanJsonStrings } from '~/utils/unicode'
import HowItWorksCuratedOrCustomUI from './CuratedOrCustom.ui'

const prices = {
  curatedSmallBox: 146,
  curatedBigBox: 269,
  customSmallBox: 169,
  customBigBox: 306,
}

export default function HowItWorksCuratedOrCustom() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query HowItWorksCuratedCustomStaticQuery {
        curatedTop: file(relativePath: { eq: "how-it-works-curated.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        customTop: file(relativePath: { eq: "how-it-works-custom.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `)
  )

  data.prices = prices

  return <HowItWorksCuratedOrCustomUI data={data} />
}
