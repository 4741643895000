import React from 'react'
import HowItWorksCuratedOrCustom from './CuratedOrCustom/CuratedOrCustom'
import HowItWorksOurBoxes from './OurBoxes/OurBoxes'

export const CuratedOrCustomAndHowItWorksContainer = () => {
  return (
    <>
      <HowItWorksOurBoxes />
      <HowItWorksCuratedOrCustom />
    </>
  )
}
