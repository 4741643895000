import React from 'react'
import type { BoxTypeExplanationProps } from './OurBoxes.types'
import AnimalIcons from '~/components/AnimalIcons/AnimalIcons'
import { IconYourChoice } from '@butcherbox/freezer'

const curatedSmallBoxPrice = 146
const customSmallBoxPrice = 169

export const BOX_TYPES_RAW: Array<BoxTypeExplanationProps> = [
  {
    id: 'box-type-custom',
    boxType: 'cst',
    boxName: 'Custom',
    description:
      'Create your perfect box by choosing from more than 25 high-quality cuts.',
    detailBullets: [
      '100% grass-fed beef, free-range organic chicken, pork raised crate-free, and wild-caught seafood options',
      '20% more meat than our curated boxes',
      'Update your cuts in every order',
    ],
    detailSubheading: 'More about the Custom Box',
    renderIcon: () => (
      <IconYourChoice customColor={{ base: 'white' }} size={'scale'} />
    ),
    numberOfMeals: 30,
    poundage: '9-14 lbs',
    startingPrice: customSmallBoxPrice,
    title: 'Custom Box',
    isTopChoice: true,
  },
  {
    id: 'box-type-mixed',
    boxType: 'beef_chicken_pork',
    boxName: 'Mixed',
    description:
      'Enjoy a selection of 100% grass-fed beef, free-range organic chicken, and pork raised crate-free.',
    detailBullets: [
      '2 lbs of Ground Beef',
      '2 New York Strip Steaks',
      '4 Top Sirloin Steaks',
      '1 lb of Whole Pork Tenderloin',
      '3 lbs of Boneless Skinless Chicken Breast',
    ],
    detailSubheading: 'A sample box could include',
    disclaimer: 'Note: Specific cuts may vary  by region and availability.',
    renderIcon: () => <AnimalIcons iconName="outlineBeefChickenPorkSquare" />,
    numberOfMeals: 24,
    poundage: '8-11 lbs',
    startingPrice: curatedSmallBoxPrice,
    title: 'Mixed Box',
    isTopChoice: false,
  },
  {
    id: 'box-type-beef-chicken',
    boxType: 'beef_and_chicken',
    boxName: 'Beef & Chicken',
    description:
      'Enjoy a curated selection of 100% grass-fed beef and free-range organic chicken.',
    detailBullets: [
      '2 lbs of Ground Beef',
      '2 New York Strip Steaks',
      '4 Top Sirloin Steaks',
      '3 lbs Drumsticks',
      '3 lbs of Boneless Skinless Chicken Breast',
    ],
    detailSubheading: 'A sample box could include',
    disclaimer: 'Note: Specific cuts may vary  by region and availability.',
    renderIcon: () => <AnimalIcons iconName="outlineBeefChicken" />,
    numberOfMeals: 24,
    poundage: '8-11 lbs',
    startingPrice: curatedSmallBoxPrice,
    title: 'Beef & Chicken Box',
    isTopChoice: false,
  },
  {
    id: 'box-type-beef-pork',
    boxType: 'beef_and_pork',
    boxName: 'Beef & Pork',
    description:
      'Enjoy a curated selection of 100% grass-fed beef and pork raised crate-free.',
    detailBullets: [
      '2 lbs of Ground Beef',
      '2 New York Strip Steaks',
      '4 Top Sirloin Steaks',
      '1 lb of Whole Pork Tenderloin',
      '4 Boneless Pork Chops',
    ],
    detailSubheading: 'A sample box could include',
    disclaimer: 'Note: Specific cuts may vary  by region and availability.',
    renderIcon: () => <AnimalIcons iconName="outlineBeefPork" />,
    numberOfMeals: 24,
    poundage: '8-11 lbs',
    startingPrice: curatedSmallBoxPrice,
    title: 'Beef & Pork Box',
    isTopChoice: false,
  },
]
