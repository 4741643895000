import { Box, CardGrid, Lockup, rem, Text } from '@butcherbox/freezer'
import { Text as CText } from '@chakra-ui/core'
import CardBadge from 'design/components/CardBadge/CardBadge'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import ChooseYourPlanButton from '~/routes/LoggedOut/HowItWorks/modules/ChooseYourPlanButton'
import { PlanComparisonProps } from './CuratedOrCustom.types'
import PlanSizeComparison from './PlanSizeComparison'
import * as Styles from './PlanComparison.css'

function PlanComparison(props: PlanComparisonProps) {
  return (
    <Box className={Styles.planComparison}>
      <CardBadge
        h={{ base: rem(80), tablet: rem(136) }}
        left={{ base: rem(-12), tablet: rem(-34) }}
        pos="absolute"
        textProps={{ lineHeight: { base: 1, tablet: 1.2 } }}
        top={{ base: rem(-12), tablet: rem(-34) }}
        w={{ base: rem(80), tablet: rem(136) }}
        zIndex={1}
      >
        Starting
        <br />
        at
        <br />
        <CText
          as="strong"
          fontFamily="inherit"
          fontSize={{ base: rem(16), tablet: rem(32) }}
        >
          ${props.startingPrice}
        </CText>
      </CardBadge>
      <GatsbyImage alt={props.imageAlt} fluid={props.imageData} />
      <Box p={{ mobile: 24, tablet: 60 }} paddingTop={0}>
        <Lockup
          marginBottom={{ mobile: 12, tablet: 24 }}
          marginTop={20}
          textAlign="center"
        >
          <Text component="h3" variant="DisplayTwo">
            {props.title}
          </Text>
          <Text variant="Body1Regular">{props.contentAboveSizes}</Text>
        </Lockup>

        <Box position="relative" px={16}>
          <CardGrid columns={{ mobile: 1, tablet: 2 }} gap={24}>
            {props.sizes.map((sizeDescription, index) => (
              <PlanSizeComparison {...sizeDescription} key={index} />
            ))}
          </CardGrid>
        </Box>
        <Box my={24}>
          <Text variant="Body1Regular">{props.contentBelowSizes}</Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <ChooseYourPlanButton />
        </Box>
      </Box>
    </Box>
  )
}

export default PlanComparison
