import { Box, Lockup, Text } from '@butcherbox/freezer'
import React from 'react'
import PlanComparison from './PlanComparison'
import * as Styles from './CuratedOrCustom.css'

export default function HowItWorksCuratedOrCustomUI({ data }) {
  return (
    <Box className={Styles.CuratedOrCustom}>
      <Lockup textAlign="center">
        <Text component="h2" variant="DisplayTwo">
          Curated or Customized
        </Text>
        <Text component="div" variant="H3Bold">
          Let us curate a box of high-quality cuts for you, or customize your
          box with the exact cuts you want.
        </Text>
      </Lockup>

      <Box className={Styles.Grid}>
        <PlanComparison
          contentAboveSizes="Explore endless possibilities for dinner with our specially curated selections of high-quality meat. Every box is a chance to try something new while enjoying a few tried-and-true favorites."
          contentBelowSizes="With ButcherBox, you don't have to think twice about quality. You'll spend less time searching and stressing, and more time enjoying delicious meals with your family."
          imageAlt="Three vignettes of ButcherBox products: pork tenderloins, steak tips, and a whole chicken."
          imageData={data.curatedTop.childImageSharp.fluid}
          sizes={[
            {
              name: 'Classic Box',
              price: data.prices.curatedSmallBox,
              poundage: '8-11 lbs',
              mealCount: 24,
            },
            {
              name: 'Big Box',
              price: data.prices.curatedBigBox,
              poundage: '16-22 lbs',
              mealCount: 48,
            },
          ]}
          startingPrice={data.prices.curatedSmallBox}
          title="Curated Box"
        />
        <PlanComparison
          contentAboveSizes="Create the perfect mix of cuts for your lifestyle. From steaks for date night to chicken breasts for meal prep, we've got you covered. Select from 25+ cuts, with the option to switch it up in every order!"
          contentBelowSizes="Get ready to raise the bar for home-cooked meals. Enjoy high-quality meat delivered to your door from ButcherBox. It's a choice you can believe in."
          imageAlt="Three vignettes of ButcherBox products: bacon, wild-caught Alaskan salmon, and filet mignon."
          imageData={data.customTop.childImageSharp.fluid}
          sizes={[
            {
              name: 'Classic Box',
              price: data.prices.customSmallBox,
              poundage: '9-14 lbs',
              mealCount: 30,
            },
            {
              name: 'Big Box',
              price: data.prices.customBigBox,
              poundage: '18-26 lbs',
              mealCount: 60,
            },
          ]}
          startingPrice={data.prices.customSmallBox}
          title="Custom Box"
        />
      </Box>
    </Box>
  )
}
