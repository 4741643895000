import { ButtonLink } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import React from 'react'
import { CTA_UNAUTHENTICATED_DEFAULT } from '~/data/constants'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import useSetCartOffer from '~/hooks/useSetCartOffer'
import { TRY_BB_URL } from '~/routes/constants'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'

export default function ChooseYourPlanButton() {
  const { cartDealUrl } = useSetCartOffer()
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    'join-586__bypassing-get-started'
  ) as [JOIN_586_VARIANTS, null, null]

  return (
    <ButtonLink
      component={Link}
      target="_self"
      to={
        shouldBypassGetStarted &&
        shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
          ? cartDealUrl
          : TRY_BB_URL
      }
    >
      {CTA_UNAUTHENTICATED_DEFAULT}
    </ButtonLink>
  )
}
