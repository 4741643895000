import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { cleanJsonStrings } from '~/utils/unicode'
import HowItWorksShippingUi from './Shipping.ui'
import { Text } from '@chakra-ui/core'

export default function HowItWorksShipping() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query HowItWorksShippingStaticQuery {
        grid: file(relativePath: { eq: "how-it-works-grid.png" }) {
          childImageSharp {
            fluid(maxWidth: 767) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `)
  )
  data.headline = 'First Class Shipping'
  data.subhead = "When it comes to shipping, we don't mess around."
  data.text = (
    <>
      Our meat is frozen for freshness, safely packaged, and delivered to your
      door in an eco-friendly, insulated box. Our boxes are specifically
      designed to keep your meat within the optimal temperature range, with dry
      ice if necessary.{' '}
      <Text as="strong" color="bb.slate" fontFamily="inherit">
        Did we mention we ship for free?
      </Text>
    </>
  )
  data.altText =
    'Vignettes of ButcherBox boxes held by people and sitting on kitchen counters'

  return <HowItWorksShippingUi data={data} />
}
