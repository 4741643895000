import {
  Box,
  CardGrid,
  IconCheck,
  IconQuality,
  Lockup,
  rem,
  Text,
} from '@butcherbox/freezer'
import CardBadge from 'design/components/CardBadge/CardBadge'
import TabbedView, { Tab } from 'design/components/TabbedView/TabbedView'
import React from 'react'
import ChooseYourPlanButton from '~/routes/LoggedOut/HowItWorks/modules/ChooseYourPlanButton'
import BoxTypeExplanation from './BoxTypeExplanation'
import BoxTypeExplanationMobile from './BoxTypeExplanationMobile'
import { BOX_TYPES_RAW } from './OurBoxes.constants'
import * as Styles from './OurBoxes.css'

export default function HowItWorksOurBoxes() {
  return (
    <Box className={Styles.OurBoxes}>
      <Lockup marginBottom={40} textAlign="center">
        <Text component="h2" variant="DisplayTwo">
          Our Boxes
        </Text>
        <Text component="div" variant="H3Bold">
          Believing in better means giving our members options—we offer 4 to
          choose from.
        </Text>
      </Lockup>

      {/* Desktop Representation */}
      <TabbedView
        display={{ mobile: 'none', tablet: 'block' }}
        label="Available Box Types"
        mobileLayout="stacked"
        tabs={BOX_TYPE_TABS}
      />
      <Box display={{ mobile: 'grid', tablet: 'none' }}>
        {BOX_TYPES_RAW.map((type) => (
          <BoxTypeExplanationMobile key={type.id} {...type} />
        ))}
      </Box>
      <CardGrid columns={{ mobile: 1, tablet: 3 }} gap={24} marginTop={8}>
        <Text color="stone" display="inlineFlex" variant="Subhead2">
          <IconCheck
            customColor={{ base: 'spicedCrimson' }}
            display="inlineBlock"
            marginRight={8}
            size="text"
          />
          grass-fed, grass-finished beef
        </Text>
        <Text color="stone" display="inlineFlex" variant="Subhead2">
          <IconCheck
            customColor={{ base: 'spicedCrimson' }}
            display="inlineBlock"
            marginRight={8}
            size="text"
          />
          free-range organic chicken
        </Text>
        <Text color="stone" display="inlineFlex" variant="Subhead2">
          <IconCheck
            customColor={{ base: 'spicedCrimson' }}
            display="inlineBlock"
            marginRight={8}
            size="text"
          />
          pork raised crate-free
        </Text>
      </CardGrid>
      <Box className={Styles.QualityCallout}>
        <IconQuality display="inlineBlock" size="small" variant="duo" />
        <Box flexShrink={0}>
          <Text variant="Body1Regular">
            Always humanely raised. No antibiotics ever.
          </Text>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" marginTop={40}>
        <ChooseYourPlanButton />
      </Box>
    </Box>
  )
}

const BOX_TYPE_TABS = BOX_TYPES_RAW.map((type) => ({
  id: type.id,
  triggerContent: (
    <>
      {type.isTopChoice && (
        <CardBadge
          left={rem(-40)}
          pos="absolute"
          textProps={{
            fontWeight: 400,
            textTransform: 'none',
          }}
          top={rem(-50)}
        >
          Top Choice
        </CardBadge>
      )}

      {type.title}
    </>
  ),
  tabContent: <BoxTypeExplanation {...type} />,
})) as Tab[]
