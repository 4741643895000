import { Box, List, ListItem, Text } from '@butcherbox/freezer'
import React from 'react'
import { PlanSizeDescriptionProps } from './CuratedOrCustom.types'
function PlanSizeComparison(props: PlanSizeDescriptionProps) {
  return (
    <Box background="ivory">
      <Box background="slate" py={8} textAlign="center">
        <Text color="white" variant="Subhead1">
          {props.name}
        </Text>
      </Box>
      <Box p={8}>
        <List aria-label={`${props.name} Size Information`}>
          <ListItem>
            $<span>{props.price}</span> per box
          </ListItem>
          <ListItem>
            <span>{props.poundage}</span> of meat
          </ListItem>
          <ListItem>{props.mealCount} meals</ListItem>
        </List>
      </Box>
    </Box>
  )
}

export default PlanSizeComparison
